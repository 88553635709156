import React, {useEffect, useState} from 'react';
import {Alert, Button, Checkbox, Col, Divider, Form, Input, message, Modal, Row, Select} from "antd";
import {Menu} from "../../../../models/Menu";
import TextArea from "antd/es/input/TextArea";
import {MenuService} from "../../../../services/MenuService";
import {ApiErrorData} from "../../../../models/ApiResponse";
import {FolderOpenOutlined} from "@ant-design/icons";
import {REPORT_TYPES_ITEMS} from "../../../../utils/Constants";
import {MenuType} from "../../../../config/Config";
import {PowerBiAdditionalConfig} from "../../../../models/PowerBiAdditionalConfig";

interface NewReportModalProps {
    code: string;
    parentName: string;
    isOpen: boolean;
    handleModalCancel: () => void;
    submit: (code: string, menuType: MenuType.IFRAME_VIEWER | MenuType.POWER_BI_REPORT, name: string, link: string | null, additionalConfig: object) => void;
}

function NewReportModal({isOpen, handleModalCancel, submit, parentName, code}: NewReportModalProps) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [selectedType, setSelectedType] = useState<MenuType.IFRAME_VIEWER | MenuType.POWER_BI_REPORT>();

    useEffect(() => {
        form.resetFields();
        setSelectedType(undefined);

        if(isOpen) {
            fetchData();
        }else {
            setLoading(false);
        }

    }, [isOpen]);

    useEffect(() => {
        if(!loading) {
            if(selectedType === MenuType.POWER_BI_REPORT) {
                form.setFieldValue('showFilterPane', true);
                form.setFieldValue('showPageNavigation', true);
            }else {
                form.setFieldValue('link', '');
                form.setFieldValue('reportId', '');
                form.setFieldValue('groupId', '');
            }
        }
    }, [selectedType]);

    const onFinish = async (values: any) => {
        if(selectedType) {
            setLoading(true);

            await submit(
                code,
                selectedType,
                values.title.trim(),
                values.link ? values.link : null,
                selectedType === MenuType.POWER_BI_REPORT ? { settings: { showFilterPane: !!values.showFilterPane, showPageNavigation: !!values.showPageNavigation }, groupId: values.groupId, reportId: values.reportId } : {}
        );

            setLoading(false);
        }
    }

    const fetchData = async () => {
        setLoading(true);

        const response = await MenuService.findByCode(code);

        if(response.success) {
            const data = response.data as Menu;
            const additionalConfig: PowerBiAdditionalConfig = data.additionalConfig;

            if(data.menuTypeId === MenuType.POWER_BI_REPORT) {
                setSelectedType(MenuType.POWER_BI_REPORT);
                form.setFieldValue('type', MenuType.POWER_BI_REPORT);
            }else {
                setSelectedType(MenuType.IFRAME_VIEWER);
                form.setFieldValue('type', MenuType.IFRAME_VIEWER);
            }

            setTimeout(() => {
                form.setFieldValue('title', data.title);
                form.setFieldValue('link', data.redirectLink || '');
                form.setFieldValue('reportId', additionalConfig.reportId || '');
                form.setFieldValue('groupId', additionalConfig.groupId || '');
                form.setFieldValue('showFilterPane', !!additionalConfig.settings?.showFilterPane);
                form.setFieldValue('showPageNavigation', !!additionalConfig.settings?.showPageNavigation);
            }, 125);

            setLoading(false);
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos del rol, por favor inténtalo nuevamente.', 3.5);
            handleModalCancel();
        }

        setLoading(false);
    }

    return (
        <>
            {contextHolder}

            <Modal
                title={`Detallles del reporte`}
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={480}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24} style={{ marginBottom: '18px' }}>
                        <Col xs={24}>
                            <Alert message="Al generarse el nuevo reporte se añadirá directamente al contenedor padre." type="info" showIcon />
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Contenedor padre"
                            >
                                <Input type="text" addonBefore={<FolderOpenOutlined />} placeholder="Contenedor" readOnly defaultValue={parentName}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider style={{ marginTop: '0', marginBottom: '15px' }}/>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="title"
                                label="Título"
                                rules={[{ required: true, message: 'Debes de ingresar el título' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese el título"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="type"
                                label="Tipo de reporte"
                                rules={[{ required: true, message: 'Debes de seleccionar una opción' }]}
                            >
                                <Select
                                    placeholder="Seleccione una opción"
                                    options={REPORT_TYPES_ITEMS}
                                    allowClear={true}
                                    onChange={(newValue) => {
                                        setSelectedType(newValue);
                                    }}
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {
                        selectedType === MenuType.IFRAME_VIEWER && (
                            <Row gutter={24}>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        name="link"
                                        label="Enlace url"
                                        rules={[
                                            { required: true, message: 'Debes de ingresar el enlace url' },
                                            {
                                                validator: (_, value) => {
                                                    if (!value) return Promise.resolve();
                                                    const urlRegex = /^(https?:\/\/)([\w-]+\.)+[\w-]+(\/[\w-.]*)*(\?.*)?(#.*)?$/;
                                                    if (!urlRegex.test(value)) {
                                                        return Promise.reject(new Error('Debes ingresar un enlace válido que comience con http:// o https://'));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            placeholder="Ingrese el enlace url del reporte"
                                            rows={4}
                                            disabled={loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    }

                    {
                        selectedType === MenuType.POWER_BI_REPORT && (
                            <>
                                <Row gutter={24}>
                                    <Col xs={24} lg={24}>
                                        <Form.Item
                                            name="reportId"
                                            label="ID del reporte"
                                            rules={[{ required: true, message: 'Debes de ingresar el ID del reporte' }]}
                                        >
                                            <Input type="text" placeholder="Ingrese el ID del reporte" disabled={loading}/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col xs={24} lg={24}>
                                        <Form.Item
                                            name="groupId"
                                            label="ID del grupo o espacio de trabajo"
                                            rules={[{ required: true, message: 'Debes de ingresar el ID del grupo o espacio de trabajo' }]}
                                        >
                                            <Input type="text" placeholder="Ingrese el ID del grupo o espacio de trabajo" disabled={loading}/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col xs={24} lg={24}>
                                        <Form.Item
                                            name="showFilterPane"
                                            valuePropName="checked"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Checkbox disabled={loading}>Deseo mostrar el panel de filtros del reporte.</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col xs={24} lg={24}>
                                        <Form.Item
                                            name="showPageNavigation"
                                            valuePropName="checked"
                                        >
                                            <Checkbox disabled={loading}>Deseo habilitar la navegación entre páginas del reporte.</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )
                    }

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default NewReportModal;
