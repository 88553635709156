import React, {useEffect, useState} from 'react';
import {Alert, Button, Checkbox, Col, Divider, Form, Input, message, Modal, Row, Select} from "antd";
import PhoneInput from "antd-phone-input";
import {Role} from "../../../../models/Role";
import {ItemInterface} from "../../../../models/ItemInterface";
import {ApiErrorData} from "../../../../models/ApiResponse";
import {UserService} from "../../../../services/UserService";
import {User} from "../../../../models/User";
import {Company} from "../../../../models/Company";

interface DetailUserModalProps {
    isOpen: boolean;
    id: number;
    handleModalCancel: () => void;
    submit: (id: number, name: string, lastname: string, email: string, dialCode: string | null, phone: string | null, roleIds: number[]) => void;
    roles: Role[];
    defaultCountryCode: string;
    getDialCode: (countryCode: string) => string;
}

function DetailUserModal({isOpen, handleModalCancel, submit, roles, id, defaultCountryCode, getDialCode}: DetailUserModalProps) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState<Company>();

    useEffect(() => {
        form.resetFields();
        form.setFieldValue('phone', {...form.getFieldValue('phone'), countryCode: parseInt(defaultCountryCode)});

        if(isOpen) {
            fetchData();
        }else {
            setLoading(false);
        }

    }, [isOpen]);

    const fetchData = async () => {
        setLoading(true);

        const response = await UserService.findById(id);

        if(response.success) {
            const data = response.data as User;
            form.setFieldValue('name', data.name);
            form.setFieldValue('company', data.company.name);
            form.setFieldValue('lastname', data.lastname);
            form.setFieldValue('email', data.email);
            form.setFieldValue('roleIds', data.roles.map((record) => {
                return record.id;
            }));

            setSelectedCompany(data.company);

            if((data.phone && data.dialCode))  {
                let newPhone = {...form.getFieldValue('phone'), countryCode: parseInt(data.dialCode), areaCode: null, phoneNumber: null};

                if(data.phone) {
                    newPhone.areaCode = data.phone;
                }

                form.setFieldValue('phone', newPhone);
            }else {
                form.setFieldValue('phone', {...form.getFieldValue('phone'), countryCode: parseInt(getDialCode(data.company.countryCode))});
            }

            setLoading(false);
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos del usuario, por favor inténtalo nuevamente.', 3.5);
            handleModalCancel();
        }

        setLoading(false);
    }

    const onFinish = async (values: any) => {
        setLoading(true);

        await submit(
            id,
            values.name,
            values.lastname,
            values.email,
            values.phone.phoneNumber === null ? null : `+${values.phone.countryCode}` ,
            values.phone.phoneNumber === null ? null : `${values.phone.areaCode}${values.phone.phoneNumber}`,
            values.roleIds || []
        );

        setLoading(false);
    }

    const getRoles = (): ItemInterface[] => {
        return roles
            .filter((record) => {
                return record.companyId === selectedCompany?.id
            })
            .map((record) => {
                return {
                    value: record.id,
                    label: record.name
                };
            });
    }

    return (
        <>
            {contextHolder}

            <Modal
                title={`Ver usuario [ID: ${id}]`}
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={480}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="company"
                                label="Compañía"
                            >
                                <Input type="text" disabled={loading} readOnly placeholder="Compañía"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="email"
                                label="Correo electrónico"
                                rules={[
                                    { required: true, message: 'Debes ingresar el correo electrónico.' },
                                    {
                                        type: 'email',
                                        message: 'El correo electrónico no es válido.'
                                    }
                                ]}
                            >
                                <Input
                                    type="text"
                                    disabled={loading}
                                    placeholder="Ingrese el correo electrónico"
                                    addonAfter={'@'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="name"
                                label="Nombres"
                                rules={[{ required: true, message: 'Debes de ingresar el nombre' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese los nombres"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="lastname"
                                label="Apellidos"
                                rules={[{ required: true, message: 'Debes de ingresar los apellidos' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese los apellidos"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                name="phone"
                                label="Teléfono"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (!value) {
                                                return Promise.resolve();
                                            }

                                            if (value.valid()) {
                                                return Promise.resolve();
                                            }

                                            if (!value.valid() && value.phoneNumber === null) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error('Debes ingresar un número de teléfono válido.'));
                                        },
                                    },
                                ]}
                            >
                                <PhoneInput
                                    enableSearch
                                    searchNotFound="No se encontró el país"
                                    searchPlaceholder="Buscar país"
                                    allowClear
                                    placeholder="Ingrese el teléfono"
                                    disabled={loading}
                                    onChange={(v) => {
                                        if(!v.countryCode && !v.phoneNumber && !v.areaCode) {
                                            form.setFieldValue('phone', {...form.getFieldValue('phone'), countryCode: (selectedCompany ? parseInt(getDialCode(selectedCompany!.countryCode)) : parseInt(defaultCountryCode))});
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="roleIds"
                                label="Roles"
                            >
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    options={getRoles()}
                                    disabled={loading}
                                    placeholder="Seleccione uno o más roles"
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default DetailUserModal;
