import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Divider, Form, Input, message, Modal, Row, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Country } from '../../../../models/Country';

interface NewCompanyModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    submit: (name: string, countryCode: string) => void;
    countries: Country[];
}

function NewCompanyModal({isOpen, handleModalCancel, submit, countries}: NewCompanyModalProps) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(isOpen) {
            form.resetFields();
        }
    }, [isOpen]);

    const onFinish = async (values: any) => {
        setLoading(true);

        await submit(
            values.name.trim(),
            values.country
        );

        setLoading(false);
    }

    return (
        <>
            {contextHolder}

            <Modal
                title="Crear compañía"
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={480}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="name"
                                label="Nombre"
                                rules={[{ required: true, message: 'Debes de ingresar el nombre' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese el nombre"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="country"
                                label="País"
                                rules={[{ required: true, message: 'Debes de seleccionar una opción para el icono' }]}
                            >
                                <Select
                                    placeholder="Seleccione una opción"
                                    options={countries.map((record) => {
                                        return {
                                            value: record.code,
                                            label: record.name
                                        };
                                    })}
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default NewCompanyModal;
