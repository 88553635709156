import React, {useEffect, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import {
    Button,
    Card, Col,
    Dropdown,
    GetProp, MenuProps,
    message,
    Row,
    Table,
    TablePaginationConfig,
    TableProps,
} from "antd";
import dayjs from "dayjs";
import {
    AlignLeftOutlined,
    EyeOutlined,
    MoreOutlined,
    PlusOutlined
} from "@ant-design/icons";
import Column from "antd/es/table/Column";
import Search from "antd/es/input/Search";
import {SearchProps} from "antd/lib/input";
import {ApiErrorData} from "../../../models/ApiResponse";
import {FunctionsHelper} from "../../../utils/FunctionsHelper";
import {MenuService} from "../../../services/MenuService";
import {Menu} from "../../../models/Menu";
import { Company } from '../../../models/Company';
import { CountryService } from '../../../services/CountryService';
import { CompanyService } from '../../../services/CompanyService';
import { Country } from '../../../models/Country';
import NewCompanyModal from './Components/NewCompanyModal';
import DetailCompanyModal from './Components/DetailCompanyModal';

import './Companies.scss';
import MenuDetailModal from './Components/MenuDetailModal';

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const PAGE_SIZE = 10;

function Companies() {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableLoading, setTableLoading] = useState(true);
    const [dataSource, setDataSource] = useState<Company[]>([]);
    const [filteredDataSource, setFilteredDataSource] = useState<Company[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
    });
    const [selectedRow, setSelectedRow] = useState<Company | undefined>(undefined);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [isNewResourceModalOpen, setIsNewResourceModalOpen] = useState(false);
    const [isMenuDetailModalOpen, setIsMenuDetailModalOpen] = useState(false);
    const [menus, setMenus] = useState<Menu[]>([]);
    const [countries, setCountries] = useState<Country[]>([]);

    /* Filters */
    const [term, setTerm] = useState('');

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        fetchData(tableParams.pagination?.current);
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        fetchData(1);
    }, [term]);

    const init = async () => {
        const [
            menuResponse,
            countryResponse
        ] = await Promise.all([
            MenuService.getAll(),
            CountryService.getAll(),
            fetchDataFromServer(false)
        ]);

        if(menuResponse.success && countryResponse.success) {
            setMenus(menuResponse.data as Menu[]);
            setCountries(countryResponse.data as Country[]);
            setTableLoading(false);
        }else {
            if(!menuResponse.success){
                const error = menuResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los menus asociados a la compañía.', 3.5);
            }
            
            if(!countryResponse.success){
                const error = countryResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los países.', 3.5);
            }
        }
    }

    const findCountryByCode = (code: string) => {
        return countries.find((record) => {
            return record.code === code;
        });
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    }

    const createResourceModal = () => {
        setIsNewResourceModalOpen(true);
    }

    const showDetailResource = async (row: Company) => {
        setSelectedRow(row);
        setIsDetailModalOpen(true);
    }

    const showMenuDetailModalOpen = async (row: Company) => {
        setSelectedRow(row);
        setIsMenuDetailModalOpen(true);
    }

    const handleCloseNewResourceModal = () => {
        setIsNewResourceModalOpen(false);
    }

    const handleCloseShowDetailResourceModal = () => {
        setSelectedRow(undefined);
        setIsDetailModalOpen(false);
    }

    const handleCloseMenuDetailModal = () => {
        setSelectedRow(undefined);
        setIsMenuDetailModalOpen(false);
    }

    const getDropdownMenu = (row: Company): MenuProps['items'] => {
        const menus: MenuProps['items'] = [];
        let itemNro = 1;

        menus.push({
            key: `${itemNro++}`,
            label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver detalle</span>,
            onClick: () => { showDetailResource(row); }
        });

        menus.push({
            key: `${itemNro++}`,
            label: <span><AlignLeftOutlined style={{ marginRight: '8px' }}/> Menus</span>,
            onClick: () => { showMenuDetailModalOpen(row); }
        });

        return menus;
    }

    const handleTableChange: TableProps<Company>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setFilteredDataSource([]);
        }
    };

    const fetchDataFromServer = async (withLoading: boolean = false) => {
        if(withLoading) {
            setTableLoading(true);
        }
        const response = await CompanyService.getAll();

        if(response.success) {
            setDataSource(response.data as Company[]);
            fetchData(1, response.data as Company[]);
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de las compañía.', 3.5);
        }

        if(withLoading) {
            setTableLoading(false);
        }
    }

    const fetchData = (currentPage: number = 1, data?: Company[]) => {
        const termFilter = FunctionsHelper.normalizeText(term.trim());

        let filteredData  = data !== undefined ? data : [...dataSource];

        filteredData = filteredData.sort((a, b) => {
            return b.id - a.id;
        });

        if(termFilter.length > 0) {
            filteredData  = filteredData.filter((record) => {
                return FunctionsHelper.normalizeText(record.name).includes(termFilter);
            });
        }

        const pageSize = tableParams.pagination?.pageSize || PAGE_SIZE;
        const total = filteredData.length;
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = filteredData.slice(startIndex, endIndex);

        setFilteredDataSource(paginatedData);
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: currentPage,
                total: total
            }
        });
    }

    const createNewCompany = async (name: string, countryCode: string) => {
        setTableLoading(true);

        const response = await CompanyService.create({ name, countryCode });

        if(response.success) {
            await fetchDataFromServer(true);
            messageApi.success(`Se creo satisfactoriamente la compañía ${name} con ID: ${(response.data as { createdId: number }).createdId}`);
            handleCloseNewResourceModal();
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar crear la nueva compañía.', 3.5);
        }

        setTableLoading(false);
    }

    const editCompany = async (id: number, name: string) => {
        setTableLoading(true);

        const response = await CompanyService.update(id, { name });

        if(response.success) {
            await fetchDataFromServer(true);
            messageApi.success(`Se guardaron los cambios satisfactoriamente de la compañía ${name} con ID: ${id}`);
            handleCloseShowDetailResourceModal();
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar crear actualizar los datos de la compañía.', 3.5);
        }

        setTableLoading(false);
    }

    const associateCompanyAndMenus = async (company: Company, keys: string[]) => {
        const response = await MenuService.associateMenusWithCompany({
            companyId: company.id,
            menus: keys
        });

        if(response.success) {
            messageApi.success(<span>Se actualizó la visibilidad de los menus a los cuales tiene acceso la  compañía: <b>{company.name}</b> de manera satisfactoria.</span>, 3.5);
            handleCloseMenuDetailModal();
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message || 'Hubo un error al intentar asociar los menus a la compañía, por favor inténtalo nuevamente.', 3.5);
        }
    }


    return (
        <>
            { contextHolder }

            <Layout breadcrumb={[
                { title: 'Ajustes' },
                { title: 'Compañías' },
            ]}>
                <Card>
                    <div className="filter-container">
                        <Row gutter={[24, 16]}>
                            <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                                <label className="filter-search-container">Filtrar por: <Search placeholder="Nombre" onSearch={onSearch} disabled={tableLoading} allowClear/></label>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container">
                                <Button type="primary" onClick={createResourceModal}  disabled={tableLoading}><PlusOutlined /> Crear compañía</Button>
                            </Col>
                        </Row>
                    </div>
                </Card>

                <Card style={{ marginTop: '12px' }}>
                    <Table<Company> dataSource={filteredDataSource} bordered loading={tableLoading} size="small" scroll={{ x: 1300 }} pagination={tableParams.pagination} rowKey={(record) => record.id} onChange={handleTableChange}>
                        <Column title="ID" dataIndex="id" key="id" width={60} />

                        <Column title="Nombre" key="name" render={(row: Company) => (
                            <span>
                                {row.name}
                            </span>
                        )}/>

                        <Column width={160} title="País" key="country" render={(row: Company) => (
                            <span>
                                {findCountryByCode(row.countryCode)?.name || row.countryCode}
                            </span>
                        )}/>

                        <Column
                            width={160}
                            align="center"
                            title="Fecha de creación"
                            dataIndex="createdAt"
                            key="createdAt"
                            render={(createdAt: Date) => (
                                <span>{dayjs(createdAt).format('DD/MM/YYYY h:mm A')}</span>
                            )}
                        />

                        <Column width={120} align="center" title="" key="actions" render={(row) => (
                            <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                                <Button size="small"><MoreOutlined /></Button>
                            </Dropdown>
                        )} />
                    </Table>
                </Card>

                {/* Modals */}
                <NewCompanyModal
                    isOpen={isNewResourceModalOpen}
                    handleModalCancel={handleCloseNewResourceModal}
                    submit={createNewCompany}
                    countries={countries}
                />

{
                    selectedRow && (
                        <DetailCompanyModal
                            isOpen={isDetailModalOpen}
                            id={selectedRow.id}
                            handleModalCancel={handleCloseShowDetailResourceModal}
                            submit={editCompany}
                            countries={countries}
                        />
                    )
                }

{
                    selectedRow && (
                        <MenuDetailModal
                            company={selectedRow}
                            isOpen={isMenuDetailModalOpen}
                            handleModalCancel={handleCloseMenuDetailModal}
                            menus={menus}
                            submit={associateCompanyAndMenus}/>
                    )
                }
            </Layout>
        </>
    );
}

export default Companies;
