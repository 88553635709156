import React from 'react';
import Icon, {
    AuditOutlined,
    CalendarOutlined,
    CaretRightOutlined,
    CloudServerOutlined,
    CodeOutlined,
    DownloadOutlined,
    FolderOutlined,
    GlobalOutlined,
    HomeOutlined,
    QuestionCircleOutlined,
    SwitcherOutlined,
    UserOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {CustomIconComponentProps} from "@ant-design/icons/es/components/Icon";
import { ReactComponent as BukSvg } from '../../assets/icons/buk_icon.svg';
import { ReactComponent as GeoVictoriaSvg } from '../../assets/icons/geovictoria_icon.svg';

export interface DynamicIconProps {
    type: string | null;
}

const BukIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={BukSvg} {...props} />
);

const GeoVictoriaIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={GeoVictoriaSvg} {...props} />
);

export const icons: Record<string, React.ReactNode> = {
    'HomeOutlined': <HomeOutlined/>,
    'WarningOutlined': <WarningOutlined/>,
    'QuestionCircleOutlined': <QuestionCircleOutlined />,
    'AuditOutlined': <AuditOutlined />,
    'CalendarOutlined': <CalendarOutlined />,
    'CodeOutlined': <CodeOutlined />,
    'DownloadOutlined': <DownloadOutlined />,
    'UserOutlined': <UserOutlined />,
    'CaretRightOutlined': <CaretRightOutlined/>,
    'FolderOutlined': <FolderOutlined />,
    'SwitcherOutlined': <SwitcherOutlined />,
    'CloudServerOutlined': <CloudServerOutlined />,
    'GlobalOutlined': <GlobalOutlined />,
    'Buk': <BukIcon />,
    'GeoVictoria': <GeoVictoriaIcon />
};

function DynamicIcon({ type }: DynamicIconProps) {
    return (
        <>
            {
                type && (
                    icons[type] ? icons[type] : icons['WarningOutlined']
                )
            }
        </>
    );
}

export default DynamicIcon;
