import React, {useEffect} from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import GuestRoute from './components/GuestRoute';
import PrivateRoute from './components/PrivateRoute';
import Home from '../scenes/Home/Home';
import Login from '../scenes/Login/Login';
import NotFound from '../scenes/NotFound/NotFound';
import {User} from "../models/User";
import {useDispatch, useSelector} from "react-redux";
import {MenuType} from "../config/Config";
import DynamicViewer from "../scenes/DynamicViewer/DynamicViewer";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import dashboardMenu from "../config/DashboardMenu";
import MenuAction from "../redux/actions/menuActions/Actions";
import PasswordRecovery from "../scenes/PasswordRecovery/PasswordRecovery";
import GeoVictoriaToBukMigration from "../scenes/GeoVictoria/GeoVictoriaToBukMigration/GeoVictoriaToBukMigration";
import GeoVictoriaToBukDateConfiguration
    from "../scenes/GeoVictoria/GeoVictoriaToBukDateConfiguration/GeoVictoriaToBukDateConfiguration";
import BukToGeoVictoriaMigration from "../scenes/Buk/BukToGeoVictoriaMigration/BukToGeoVictoriaMigration";
import Users from "../scenes/Settings/Users/Users";
import Roles from "../scenes/Settings/Roles/Roles";
import ReportAndMenus from "../scenes/Settings/ReportAndMenus/ReportAndMenus";
import Companies from "../scenes/Settings/Companies/Companies";
import SuperUsers from "../scenes/Settings/SuperUsers/SuperUsers";
import PowerBiReport from "../scenes/PowerBiReport/PowerBiReport";

export default function AppRoutes() {
    const location = useLocation();
    const dispatch = useDispatch();
    let protectedFirstRoute = "/home";
    const user: User = useSelector((state: any) => state.auth);
    const { routes: menusApp } = dashboardMenu(user);
    const menus = user.allowedMenus.filter((menu) => {
        return [
            MenuType.STANDARD_MENU as string,
            MenuType.IFRAME_VIEWER as string,
            MenuType.PARENT_MENU as string
        ].includes(menu.menuTypeId);
    });

    if (menus.length > 0) {
        const firstMenu = menus[0];
        if (firstMenu) {
            if (firstMenu.menuTypeId === MenuType.STANDARD_MENU && firstMenu.redirectLink) {
                protectedFirstRoute = firstMenu.redirectLink;
            }

            if (firstMenu.menuTypeId === MenuType.IFRAME_VIEWER && firstMenu.redirectLink) {
                protectedFirstRoute = `/dynamic-viewer/${firstMenu.code}`;
            }

            if (firstMenu.menuTypeId === MenuType.POWER_BI_REPORT) {
                protectedFirstRoute = `/pbi-viewer/${firstMenu.code}`;
            }

            if (firstMenu.menuTypeId === MenuType.PARENT_MENU) {
                const childrenMenus = firstMenu.menus.filter((menu) => {
                    return [
                        MenuType.STANDARD_MENU as string,
                        MenuType.IFRAME_VIEWER as string
                    ].includes(menu.menuTypeId);
                });
                if(childrenMenus.length > 0) {
                    const firstChildrenMenu = childrenMenus[0];

                    if(firstChildrenMenu.menuTypeId === MenuType.STANDARD_MENU && firstChildrenMenu.redirectLink) {
                        protectedFirstRoute = firstChildrenMenu.redirectLink;
                    }

                    if(firstChildrenMenu.menuTypeId === MenuType.IFRAME_VIEWER && firstChildrenMenu.redirectLink) {
                        protectedFirstRoute = `/dynamic-viewer/${firstChildrenMenu.code}`;
                    }

                    if(firstChildrenMenu.menuTypeId === MenuType.POWER_BI_REPORT) {
                        protectedFirstRoute = `/pbi-viewer/${firstChildrenMenu.code}`;
                    }
                }
            }
        }
    }

    useEffect(() => {
        const localMenus = FunctionsHelper.flattenMenuArrayDashboardMenu([...menusApp]);
        const localSelectedKey = localMenus.find((row) => {
            const path = (row.path || '').toLowerCase();
            return location.pathname === path;
        });

        if(localSelectedKey) {
            dispatch(MenuAction.update({ selectedKeys: localSelectedKey.keys }));

        }else {
            dispatch(MenuAction.remove());
        }
    }, [location]);

  return (
    <Routes>
        <Route path="/" element={<Navigate to={protectedFirstRoute} />} />
        <Route path="/home" element={<PrivateRoute outlet={<Home />} />} />
        <Route path="/login" element={<GuestRoute outlet={<Login />} />} />
        <Route path="/reset-password/:token" element={<GuestRoute outlet={<PasswordRecovery />} />} />
        <Route
            path="/dynamic-viewer/:key"
            element={
                <PrivateRoute
                    outlet={<DynamicViewer />}
                    type={MenuType.IFRAME_VIEWER}
                />
            }
        />

        <Route
            path="/pbi-viewer/:key"
            element={
                <PrivateRoute
                    outlet={<PowerBiReport />}
                    type={MenuType.POWER_BI_REPORT}
                />
            }
        />

        <Route
            path="/geo-victoria/buk-migration"
            element={
                <PrivateRoute
                    outlet={<GeoVictoriaToBukMigration />}
                />
            }
        />
        <Route
            path="/geo-victoria/date-configuration"
            element={
                <PrivateRoute
                    outlet={<GeoVictoriaToBukDateConfiguration />}
                />
            }
        />
        <Route
            path="/buk/geo-victoria-migration"
            element={
                <PrivateRoute
                    outlet={<BukToGeoVictoriaMigration />}
                />
            }
        />

        {/* Settings */}
        <Route
            path="/settings/companies"
            element={
                <PrivateRoute
                    outlet={<Companies />}
                />
            }
        />

        <Route
            path="/settings/super-users"
            element={
                <PrivateRoute
                    outlet={<SuperUsers />}
                />
            }
        />


        <Route
            path="/settings/users"
            element={
                <PrivateRoute
                    outlet={<Users />}
                />
            }
        />

        <Route
            path="/settings/roles"
            element={
                <PrivateRoute
                    outlet={<Roles />}
                />
            }
        />

        <Route
            path="/settings/reports-and-menus"
            element={
                <PrivateRoute
                    outlet={<ReportAndMenus />}
                />
            }
        />

        <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
