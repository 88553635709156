import React, {useEffect, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import {
    Button,
    Card, Col,
    Dropdown,
    GetProp, MenuProps,
    message, Modal,
    Row,
    Table,
    TablePaginationConfig,
    TableProps, Tag, Tooltip
} from "antd";
import {
    ExclamationCircleOutlined,
    EyeOutlined, LockOutlined, MailOutlined,
    MoreOutlined,
    PlusOutlined, UnlockOutlined
} from "@ant-design/icons";
import Column from "antd/es/table/Column";
import Search from "antd/es/input/Search";
import {SearchProps} from "antd/lib/input";
import {RoleService} from "../../../services/RoleService";
import {ApiErrorData} from "../../../models/ApiResponse";
import {FunctionsHelper} from "../../../utils/FunctionsHelper";
import {User} from "../../../models/User";
import {UserService} from "../../../services/UserService";
import {Role} from "../../../models/Role";
import {useSelector} from "react-redux";
import {Company} from "../../../models/Company";
import {CompanyService} from "../../../services/CompanyService";
import NewUserModal from "./Components/NewUserModal";
import DetailUserModal from "./Components/DetailUserModal";

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const PAGE_SIZE = 10;

function SuperUsers() {
    const [messageApi, contextHolder] = message.useMessage();
    const [modal, contextModalHolder] = Modal.useModal();
    const authUser: User = useSelector((state: any) => state.auth);
    const [tableLoading, setTableLoading] = useState(true);
    const [dataSource, setDataSource] = useState<User[]>([]);
    const [filteredDataSource, setFilteredDataSource] = useState<User[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
    });
    const [roles, setRoles] = useState<Role[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);

    const [selectedRow, setSelectedRow] = useState<User | undefined>(undefined);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [isNewResourceModalOpen, setIsNewResourceModalOpen] = useState(false);

    /* Filters */
    const [term, setTerm] = useState('');

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        fetchData(tableParams.pagination?.current);
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        fetchData(1);
    }, [term]);

    const init = async () => {
        setTableLoading(true);

        const [
            roleResponse,
            companyResponse
        ] = await Promise.all([
            RoleService.getAll(),
            CompanyService.getAll(),
            fetchDataFromServer(false)
        ]);

        if(roleResponse.success && companyResponse.success) {
            setRoles(roleResponse.data as Role[]);
            setCompanies(companyResponse.data as Company[]);
        }else {
            if(!roleResponse.success) {
                const error = roleResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los roles, por favor inténtalo nuevamente.', 3.5);
            }

            if(!companyResponse.success) {
                const error = companyResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener las compañías, por favor inténtalo nuevamente.', 3.5);
            }
        }

        setTableLoading(false);
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    }

    const createResourceModal = () => {
        setIsNewResourceModalOpen(true);
    }

    const showDetailResource = async (row: User) => {
        setSelectedRow(row);
        setIsDetailModalOpen(true);
    }

    const handleCloseNewResourceModal = () => {
        setIsNewResourceModalOpen(false);
    }

    const handleCloseShowDetailResourceModal = () => {
        setSelectedRow(undefined);
        setIsDetailModalOpen(false);
    }

    const getDropdownMenu = (row: User): MenuProps['items'] => {
        const menus: MenuProps['items'] = [];
        let itemNro = 1;

        menus.push({
            key: `${itemNro++}`,
            label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver detalle</span>,
            onClick: () => { showDetailResource(row); }
        });

        menus.push({
            key: `${itemNro++}`,
            label: <span><MailOutlined style={{ marginRight: '8px' }}/> Restablecer contraseña y notificar</span>,
            onClick: () => { resetAndResendPassword(row); }
        });

        if(row.disabledAt) {
            menus.push({
                key: `${itemNro++}`,
                label: <span><UnlockOutlined style={{ marginRight: '8px' }}/> Activar usuario</span>,
                onClick: async () => { await setUserAsActive(row); }
            });
        }else {
            menus.push({
                key: `${itemNro++}`,
                label: <span><LockOutlined style={{ marginRight: '8px' }}/> Desactivar usuario</span>,
                onClick: async () => { await setUserAsInactive(row); },
                danger: true
            });
        }

        return menus;
    }

    const setUserAsInactive = async (user: User) => {
        setTableLoading(true);
        const response = await UserService.disableUser(user.id);

        if(response.success) {
            await fetchDataFromServer();
            messageApi.success(<span>Se desactivó al usuario con el correo <b>{user.email}</b> y con el ID <b>{user.id}</b> de manera satisfactoria.</span>)
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar desactivar al usuario, por favor inténtalo nuevamente.', 3.5);
        }

        setTableLoading(false);
    }

    const setUserAsActive = async (user: User) => {
        setTableLoading(true);
        const response = await UserService.enableUser(user.id);

        if(response.success) {
            await fetchDataFromServer();
            messageApi.success(<span>Se activó al usuario con el correo <b>{user.email}</b> y con el ID <b>{user.id}</b> de manera satisfactoria.</span>)
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar activar al usuario, por favor inténtalo nuevamente.', 3.5);
        }

        setTableLoading(false);
    }

    const resetAndResendPassword = async (user: User) => {
        modal.confirm({
            title: 'Confirmación',
            icon: <ExclamationCircleOutlined />,
            content: <span>¿Estás seguro que deseas restablecer la contraseña del usuario <b>{user.name} {user.lastname}</b> y notificarle por el correo electrónico <b>{user.email}</b>?</span>,
            okText: 'SI',
            cancelText: 'NO',
            onOk: async () => {
                setTableLoading(true);
                const response = await UserService.resetAndSendNewPassword(user.id);

                if(response.success) {
                    await fetchDataFromServer();
                    messageApi.success(<span>Se restableció la contraseña y se notificó al usuario con ID {user.id} de manera satisfactoria.</span>)
                }else {
                    const error = response.data as ApiErrorData;
                    messageApi.error(error.message as string || 'Hubo un error al intentar restablecer la contraseña, por favor inténtalo nuevamente.', 3.5);
                }

                setTableLoading(false);
            }
        });
    }

    const handleTableChange: TableProps<User>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setFilteredDataSource([]);
        }
    };

    const fetchDataFromServer = async (withLoading: boolean = false) => {
        if(withLoading) {
            setTableLoading(true);
        }
        const response = await UserService.getAllSuperUsers();

        if(response.success) {
            setDataSource(response.data as User[]);
            fetchData(1, response.data as User[]);
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de los usuarios.', 3.5);
        }

        if(withLoading) {
            setTableLoading(false);
        }
    }

    const fetchData = (currentPage: number = 1, data?: User[]) => {
        const termFilter = FunctionsHelper.normalizeText(term.trim());

        let filteredData  = data !== undefined ? data : [...dataSource];

        filteredData = filteredData.sort((a, b) => {
            return b.id - a.id;
        });

        if(termFilter.length > 0) {
            filteredData  = filteredData.filter((record) => {
                return FunctionsHelper.normalizeText(`${record.name} ${record.lastname}`).includes(termFilter) || FunctionsHelper.normalizeText(record.email).includes(termFilter);
            });
        }

        const pageSize = tableParams.pagination?.pageSize || PAGE_SIZE;
        const total = filteredData.length;
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = filteredData.slice(startIndex, endIndex);

        setFilteredDataSource(paginatedData);
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: currentPage,
                total: total
            }
        });
    }

    const createNewUser = async (name: string, lastname: string, email: string, dialCode: string | null, phone: string | null, roleIds: number[], companyId: number) => {
        setTableLoading(true);

        const response = await UserService.createActiveUserAndSendPassword({
            name,
            lastname,
            email,
            dialCode,
            phone,
            roleIds,
            companyId,
            userType: 'SUPER_ADMIN'
        });

        if(response.success) {
            await fetchDataFromServer(true);
            messageApi.success(`Se creo satisfactoriamente el usuario ${name} ${lastname} con ID: ${(response.data as { createdId: number }).createdId}`);
            handleCloseNewResourceModal();
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar crear el nuevo usuario.', 3.5);
        }

        setTableLoading(false);
    }

    const editUser = async (id: number, name: string, lastname: string, email: string, dialCode: string | null, phone: string | null, roleIds: number[]) => {
        setTableLoading(true);

        const response = await UserService.update(id, {
            name,
            lastname,
            email,
            dialCode,
            phone,
            roleIds
        });

        if(response.success) {
            await fetchDataFromServer(true);
            messageApi.success(`Se actualizaron los datos del usuario ${name} ${lastname} con ID: ${id} satisfactoriamente.`);
            handleCloseShowDetailResourceModal();
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar actualizar el usuario.', 3.5);
        }

        setTableLoading(false);
    }

    const getDialCode = (countryCode: string): string => {
        let dialCode = "+56";

        switch (countryCode.toLowerCase()) {
            case 'pe':
                dialCode = "+51";
                break;
            case 'usa':
                dialCode = "+1";
                break;
            case 'mx':
                dialCode = "+52";
                break;
            case 'co':
                dialCode = "+57";
                break;
        }

        return dialCode;
    }

    return (
        <>
            { contextHolder }
            { contextModalHolder }

            <Layout breadcrumb={[
                { title: 'Ajustes' },
                { title: 'Super usuarios' },
            ]}>
                <Card>
                    <div className="filter-container">
                        <Row gutter={[24, 16]}>
                            <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                                <label className="filter-search-container">Filtrar por: <Search placeholder="Nombres o correo" onSearch={onSearch} disabled={tableLoading} allowClear/></label>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container">
                                <Button type="primary" onClick={createResourceModal}  disabled={tableLoading}><PlusOutlined /> Crear Usuario</Button>
                            </Col>
                        </Row>
                    </div>
                </Card>

                <Card style={{ marginTop: '12px' }}>
                    <Table<User> dataSource={filteredDataSource} bordered loading={tableLoading} size="small" scroll={{ x: 1300 }} pagination={tableParams.pagination} rowKey={(record) => record.id} onChange={handleTableChange}>
                        <Column title="ID" dataIndex="id" key="id" width={60} />

                        <Column title="Nombres" key="fullname" render={(row: User) => (
                            <span>
                                {row.name} {row.lastname}
                            </span>
                        )}/>

                        <Column title="Correo" dataIndex="email" key="email" />

                        <Column title="Companía" key="company" render={(row: User) => (
                            <span>
                                {row.company.name}
                            </span>
                        )}/>

                        <Column width={240} align="center" title="Rol" key="roles" render={(row: User) => (
                            <>
                                {
                                    row.roles.length === 0 && (
                                        <Tag color={row.disabledAt ? 'error' : 'default'}>No presenta</Tag>
                                    )
                                }
                                {
                                    row.roles.length === 1 && (
                                        <Tag color={row.disabledAt ? 'error' : 'processing'}>{ row.roles[0].name }</Tag>
                                    )
                                }

                                {
                                    row.roles.length > 1 && (
                                        <Tooltip title={`${row.roles.map((record) => record.name).join(', ')}`}>
                                            <Tag icon={<ExclamationCircleOutlined/>} style={{ cursor: 'pointer' }} color={row.disabledAt ? 'error' : 'processing'}>Más de 1 tipo</Tag>
                                        </Tooltip>
                                    )
                                }
                            </>
                        )} />

                        <Column width={120} align="center" title="" key="actions" render={(row: User) => (
                            <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                                <Button size="small"><MoreOutlined /></Button>
                            </Dropdown>
                        )} />
                    </Table>
                </Card>

                {/* Modals */}
                <NewUserModal
                    isOpen={isNewResourceModalOpen}
                    handleModalCancel={handleCloseNewResourceModal}
                    submit={createNewUser}
                    roles={roles}
                    defaultCountryCode={getDialCode(authUser.company.countryCode)}
                    companies={companies}
                    getDialCode={getDialCode}
                />

                {
                    selectedRow && (
                        <DetailUserModal
                            isOpen={isDetailModalOpen}
                            id={selectedRow.id}
                            handleModalCancel={handleCloseShowDetailResourceModal}
                            submit={editUser}
                            roles={roles}
                            defaultCountryCode={getDialCode(authUser.company.countryCode)}
                            getDialCode={getDialCode}
                        />
                    )
                }
            </Layout>
        </>
    );
}

export default SuperUsers;
