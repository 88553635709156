import React, {useEffect, useState} from 'react';
import {Alert, Button, Checkbox, Col, Divider, Form, Input, message, Modal, Row, Select} from "antd";
import PhoneInput from "antd-phone-input";
import {Role} from "../../../../models/Role";
import {ItemInterface} from "../../../../models/ItemInterface";
import {Company} from "../../../../models/Company";
import {FunctionsHelper} from "../../../../utils/FunctionsHelper";

interface NewRoleModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    submit: (name: string, lastname: string, email: string, dialCode: string | null, phone: string | null, roleIds: number[], companyId: number) => void;
    roles: Role[];
    defaultCountryCode: string;
    companies: Company[];
    getDialCode: (countryCode: string) => string;
}

function NewUserModal({isOpen, handleModalCancel, submit, roles, defaultCountryCode, companies, getDialCode}: NewRoleModalProps) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState<Company>();

    useEffect(() => {
        form.resetFields();
        setSelectedCompany(undefined);

        if(isOpen) {
            form.setFieldValue('phone', {...form.getFieldValue('phone'), countryCode: parseInt(defaultCountryCode)})
        }

    }, [isOpen]);

    const onFinish = async (values: any) => {
        setLoading(true);

        await submit(
            values.name,
            values.lastname,
            values.email,
            values.phone.phoneNumber === null ? null : `+${values.phone.countryCode}` ,
            values.phone.phoneNumber === null ? null : `${values.phone.areaCode}${values.phone.phoneNumber}`,
            values.roleIds || [],
            values.company
        );

        setLoading(false);
    }

    const getRoles = (): ItemInterface[] => {
        return roles
            .filter((record) => {
                return record.companyId === selectedCompany?.id
            })
            .map((record) => {
            return {
                value: record.id,
                label: record.name
            };
        });
    }

    const getCompanies = (): ItemInterface[] => {
        return companies
            .map((record) => {
            return {
                value: record.id,
                label: record.name
            };
        });
    }

    const handleChangeCompany = (newValue: number) => {
        form.setFieldValue('roleIds', []);

        let dialCode = defaultCountryCode;

        if(newValue) {
            const company = companies.find((record) => {
                return record.id === newValue;
            });

            if(company){
                dialCode = getDialCode(company.countryCode);
                setSelectedCompany(company);
            }
        }else {
            setSelectedCompany(undefined);
        }

        if(!form.getFieldValue('phone').phoneNumber) {
            form.setFieldValue('phone', {...form.getFieldValue('phone'), countryCode: parseInt(dialCode)})
        }
    }

    return (
        <>
            {contextHolder}

            <Modal
                title="Crear usuario"
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={480}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Row gutter={24} style={{ marginBottom: '18px' }}>
                    <Col xs={24}>
                        <Alert message="La contraseña del usuario creado se genera de manera aleatoria y es enviada por correo electrónico." type="info" showIcon />
                    </Col>
                </Row>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="company"
                                label="Compañía"
                                rules={[{ required: true, message: 'Debes de ingresar la companía' }]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    options={getCompanies()}
                                    disabled={loading}
                                    placeholder="Seleccione una compañía"
                                    showSearch
                                    filterOption={(input, option) => {
                                        return (FunctionsHelper.normalizeText((option?.label || '')).toLowerCase().indexOf(FunctionsHelper.normalizeText(input.toLowerCase())) >= 0);
                                    }}
                                    onChange={(v) => {
                                        handleChangeCompany(v);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="email"
                                label="Correo electrónico"
                                rules={[
                                    { required: true, message: 'Debes ingresar el correo electrónico.' },
                                    {
                                        type: 'email',
                                        message: 'El correo electrónico no es válido.'
                                    }
                                ]}
                            >
                                <Input
                                    type="text"
                                    disabled={loading}
                                    placeholder="Ingrese el correo electrónico"
                                    addonAfter={'@'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="name"
                                label="Nombres"
                                rules={[{ required: true, message: 'Debes de ingresar el nombre' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese los nombres"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="lastname"
                                label="Apellidos"
                                rules={[{ required: true, message: 'Debes de ingresar los apellidos' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese los apellidos"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                name="phone"
                                label="Teléfono"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (!value) {
                                                return Promise.resolve();
                                            }

                                            if (value.valid()) {
                                                return Promise.resolve();
                                            }

                                            if (!value.valid() && value.phoneNumber === null) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error('Debes ingresar un número de teléfono válido.'));
                                        },
                                    },
                                ]}
                            >
                                <PhoneInput
                                    enableSearch
                                    searchNotFound="No se encontró el país"
                                    searchPlaceholder="Buscar país"
                                    allowClear
                                    placeholder="Ingrese el teléfono"
                                    disabled={loading}
                                    onChange={(v) => {
                                        if(!v.countryCode && !v.phoneNumber && !v.areaCode) {
                                            form.setFieldValue('phone', {...form.getFieldValue('phone'), countryCode: (selectedCompany ? parseInt(getDialCode(selectedCompany!.countryCode)) : parseInt(defaultCountryCode))});
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="roleIds"
                                label="Roles"
                            >
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    options={getRoles()}
                                    disabled={loading || !selectedCompany}
                                    placeholder="Seleccione uno o más roles"
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default NewUserModal;
