import {ItemInterface} from "../models/ItemInterface";
import {MenuType} from "../config/Config";

export const REPORT_TYPES_ITEMS: ItemInterface[] = [
    {
        value: MenuType.IFRAME_VIEWER,
        label: 'URL'
    },
    {
        value: MenuType.POWER_BI_REPORT,
        label: 'Power BI'
    }
];
