import {ApiErrorData, ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {Menu} from "../models/Menu";
import {MenuType} from "../config/Config";

export const MenuService = {
    getByCurrentCompany: async (): Promise<ApiResponse<Menu[] | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.get(`/menu/get-all-by-current-company`);

            let menus: Menu[] = response?.data?.data;
            menus = menus.sort((a, b) => {
                return (a.companyColumnOrder || 0) - (b.companyColumnOrder || 0);
            });

            return {
                success: true,
                status: response.status,
                data: menus,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAll: async (): Promise<ApiResponse<Menu[] | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.get(`/menu/get-all`);

            let menus: Menu[] = response?.data?.data;
            menus = menus.sort((a, b) => {
                return (a.companyColumnOrder || 0) - (b.companyColumnOrder || 0);
            });

            return {
                success: true,
                status: response.status,
                data: menus,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getByCompany: async (companyId: number): Promise<ApiResponse<Menu[] | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.get(`/menu/get-by-company/${companyId}`);

            let menus: Menu[] = response?.data?.data;
            menus = menus.sort((a, b) => {
                return (a.companyColumnOrder || 0) - (b.companyColumnOrder || 0);
            });

            return {
                success: true,
                status: response.status,
                data: menus,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getByRole: async (roleId: number): Promise<ApiResponse<Menu[] | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.get(`/menu/get-all-by-role/${roleId}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    createForCurrentCompany: async (body: CreateMenuForCurrentCompanyRequestDto): Promise<ApiResponse<{ createdId: string } | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.post(`/menu/create-for-current-company`, body);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    delete: async (code: string): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.delete(`/menu/delete/${code}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    reorganizeCompanyMenus: async (body: ReorganizeCompanyMenusRequestDto): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.post(`/menu/reorganize-company-menus`, body);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    associateMenusWithRole: async (body: AssociateMenuWithRoleRequestDto): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.post(`/menu/associate-menus-with-role`, body);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    findByCode: async (code: string): Promise<ApiResponse<Menu | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.get(`/menu/find-by-code/${code}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    updateForCurrentCompany: async (code: string, body: UpdateMenuForCurrentCompanyRequestDto): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.put(`/menu/update-for-current-company/${code}`, body);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    associateMenusWithCompany: async (body: AssociateMenuWithCompanyRequestDto): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.post(`/menu/associate-menus-with-company`, body);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
}

export interface CreateMenuForCurrentCompanyRequestDto {
    title: string;
    menuType: MenuType.PARENT_MENU | MenuType.IFRAME_VIEWER | MenuType.POWER_BI_REPORT,
    redirectLink: string | null;
    icon: string | null;
    parentMenuCode: string | null;
    additionalConfig: object;
}

export interface MenuReorganizeSchema {
    code: string;
    order: number;
}

export interface ReorganizeCompanyMenusRequestDto {
    currentMenu: {
        code: string;
        parentMenuCode: string | null;
    };
    menus: MenuReorganizeSchema[];
}

export interface AssociateMenuWithRoleRequestDto {
    roleId: number;
    menus: string[];
}

export interface AssociateMenuWithCompanyRequestDto {
    companyId: number;
    menus: string[];
}

export interface UpdateMenuForCurrentCompanyRequestDto {
    title: string;
    redirectLink: string | null;
    icon: string | null;
    menuType?: MenuType;
    additionalConfig: object;
}

