import {ApiErrorData, ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {Country} from "../models/Country";

export const CountryService = {
    getAll: async (): Promise<ApiResponse<Country[] | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.get(`/country/get-all`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
}
