import MainBackendAxios from '../utils/MainBackendAxios';
import {ApiErrorData, ApiResponse} from "../models/ApiResponse";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {User} from "../models/User";
import {getFormattedUserData} from "../utils/AuthUtils";

export const UserService = {
  sendPasswordRecovery: async (username: string): Promise<ApiResponse<{ userId: number, expiresInMs: number } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/user/send-recovery-token-by-username`, { username: username });

      return {
        success: true,
        status: response.status,
        data: response.data.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  sendNewActivationCode: async (userId: number): Promise<ApiResponse<{ executedAction: boolean, userId: number, expiredCodeAt: Date } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/user/send-new-activation-code/${userId}`);

      return {
        success: true,
        status: response.status,
        data: response.data.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  verifyRecoveryToken: async (token: string): Promise<ApiResponse<{ userId: number, email: string } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/user/verify-recovery-token`, { recoveryToken: token });

      return {
        success: true,
        status: response.status,
        data: response.data.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  updatePasswordByRecoveryToken: async (token: string, newPassword: string): Promise<ApiResponse<{ executedAction: boolean, user: User } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/user/update-password-by-recovery-token`, { recoveryToken: token, newPassword });
      const userData = response?.data?.data.user;

      return {
        success: true,
        status: response.status,
        data: {
          executedAction: response.data.executedAction,
          user: getFormattedUserData(userData)
        },
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  verifyUser: async (userId: number, activationCode: string): Promise<ApiResponse<{ executedAction: boolean, user: User } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/user/verify-by-activation-code/${userId}`, { activationCode });
      const userData = response?.data?.data.user;

      return {
        success: true,
        status: response.status,
        data: {
          executedAction: response.data.executedAction,
          user: getFormattedUserData(userData)
        },
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  getAllByCurrentCompany: async (): Promise<ApiResponse<User[] | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.get(`/user/get-all-by-current-company`);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  findById: async (id: number): Promise<ApiResponse<User | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.get(`/user/find-by-id/${id}`);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  disableUser: async (id: number): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.get(`/user/disable-user-account/${id}`);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  enableUser: async (id: number): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.get(`/user/enable-user-account/${id}`);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  resetAndSendNewPassword: async (id: number): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.get(`/user/reset-and-send-new-password/${id}`);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  createUserFromCurrentCompany: async (body: CreateUserFromCurrentCompanyRequestDto): Promise<ApiResponse<{ createdId: number } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/user/create-from-current-company`, body);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  update: async (id: number, body: UpdateUserRequestDto): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.put(`/user/update/${id}`, body);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  getAllSuperUsers: async (): Promise<ApiResponse<User[] | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.get(`/user/get-all-super-users`);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  createActiveUserAndSendPassword: async (body: CreateActiveUserAndSendPasswordRequestDto): Promise<ApiResponse<{ createdId: number } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/user/create-active-user-and-send-password`, body);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
};

export interface CreateUserFromCurrentCompanyRequestDto {
  name: string;
  lastname: string;
  email: string;
  dialCode: string | null;
  phone: string | null;
  roleIds: number[];
}

export interface CreateActiveUserAndSendPasswordRequestDto {
  email: string;
  name: string;
  lastname: string;
  dialCode: string | null;
  phone: string | null;
  roleIds: number[];
  userType: 'SUPER_ADMIN' | 'REGULAR_ACCOUNT',
  companyId: number;
}

export interface UpdateUserRequestDto {
  name: string;
  lastname: string;
  email: string;
  dialCode: string | null;
  phone: string | null;
  roleIds: number[];
}
