import {ApiErrorData, ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {Role} from "../models/Role";

export const RoleService = {
    getByCurrentCompany: async (): Promise<ApiResponse<Role[] | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.get(`/role/get-all-by-current-company`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAll: async (): Promise<ApiResponse<Role[] | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.get(`/role/get-all`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    findById: async (id: number): Promise<ApiResponse<Role | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.get(`/role/find-by-id/${id}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    create: async (body: CreateRoleRequestDto): Promise<ApiResponse<{ createdId: number } | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.post(`/role/create`, body);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },

    update: async (id: number, body: UpdateRoleRequestDto): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.put(`/role/update/${id}`, body);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
}

export interface CreateRoleRequestDto {
    name: string;
    description: string | null;
    isDefaultRoleForStartedAccount: boolean;
}

export interface UpdateRoleRequestDto {
    name: string;
    description: string | null;
    isDefaultRoleForStartedAccount: boolean;
}
