import React, {useEffect, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import {
    Button,
    Card, Col,
    Dropdown,
    GetProp, MenuProps,
    message,
    Row,
    Table,
    TablePaginationConfig,
    TableProps, Tag, Tooltip
} from "antd";
import dayjs from "dayjs";
import {
    AlignLeftOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    MoreOutlined,
    PlusOutlined
} from "@ant-design/icons";
import Column from "antd/es/table/Column";
import Search from "antd/es/input/Search";
import {SearchProps} from "antd/lib/input";
import {Role} from "../../../models/Role";
import {RoleService} from "../../../services/RoleService";
import {ApiErrorData} from "../../../models/ApiResponse";
import {FunctionsHelper} from "../../../utils/FunctionsHelper";
import NewRoleModal from "./Components/NewRoleModal";
import DetailRoleModal from "./Components/DetailRoleModal";

import './Roles.scss';
import MenuDetailModal from "./Components/MenuDetailModal";
import {MenuService} from "../../../services/MenuService";
import {Menu} from "../../../models/Menu";

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const PAGE_SIZE = 10;

function Roles() {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableLoading, setTableLoading] = useState(true);
    const [dataSource, setDataSource] = useState<Role[]>([]);
    const [filteredDataSource, setFilteredDataSource] = useState<Role[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
    });
    const [selectedRow, setSelectedRow] = useState<Role | undefined>(undefined);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [isNewResourceModalOpen, setIsNewResourceModalOpen] = useState(false);
    const [isMenuDetailModalOpen, setIsMenuDetailModalOpen] = useState(false);
    const [menus, setMenus] = useState<Menu[]>([]);

    /* Filters */
    const [term, setTerm] = useState('');

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        fetchData(tableParams.pagination?.current);
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        fetchData(1);
    }, [term]);

    const init = async () => {
        const [
            menuResponse
        ] = await Promise.all([
            MenuService.getByCurrentCompany(),
            fetchDataFromServer(false)
        ]);

        if(menuResponse.success) {
            setMenus(menuResponse.data as Menu[]);
            setTableLoading(false);
        }else {
            const error = menuResponse.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los menus asociados a la compañía.', 3.5);
        }
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    }

    const createResourceModal = () => {
        setIsNewResourceModalOpen(true);
    }

    const showDetailResource = async (row: Role) => {
        setSelectedRow(row);
        setIsDetailModalOpen(true);
    }

    const showMenuDetailModalOpen = async (row: Role) => {
        setSelectedRow(row);
        setIsMenuDetailModalOpen(true);
    }

    const handleCloseNewResourceModal = () => {
        setIsNewResourceModalOpen(false);
    }

    const handleCloseShowDetailResourceModal = () => {
        setSelectedRow(undefined);
        setIsDetailModalOpen(false);
    }

    const handleCloseMenuDetailModal = () => {
        setSelectedRow(undefined);
        setIsMenuDetailModalOpen(false);
    }

    const getDropdownMenu = (row: Role): MenuProps['items'] => {
        const menus: MenuProps['items'] = [];
        let itemNro = 1;

        menus.push({
            key: `${itemNro++}`,
            label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver detalle</span>,
            onClick: () => { showDetailResource(row); }
        });

        menus.push({
            key: `${itemNro++}`,
            label: <span><AlignLeftOutlined style={{ marginRight: '8px' }}/> Menus</span>,
            onClick: () => { showMenuDetailModalOpen(row); }
        });

        return menus;
    }

    const handleTableChange: TableProps<Role>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setFilteredDataSource([]);
        }
    };

    const fetchDataFromServer = async (withLoading: boolean = false) => {
        if(withLoading) {
            setTableLoading(true);
        }
        const response = await RoleService.getByCurrentCompany();

        if(response.success) {
            setDataSource(response.data as Role[]);
            fetchData(1, response.data as Role[]);
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de los roles.', 3.5);
        }

        if(withLoading) {
            setTableLoading(false);
        }
    }

    const fetchData = (currentPage: number = 1, data?: Role[]) => {
        const termFilter = FunctionsHelper.normalizeText(term.trim());

        let filteredData  = data !== undefined ? data : [...dataSource];

        filteredData = filteredData.sort((a, b) => {
            return b.id - a.id;
        });

        if(termFilter.length > 0) {
            filteredData  = filteredData.filter((record) => {
                return FunctionsHelper.normalizeText(record.name).includes(termFilter);
            });
        }

        const pageSize = tableParams.pagination?.pageSize || PAGE_SIZE;
        const total = filteredData.length;
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = filteredData.slice(startIndex, endIndex);

        setFilteredDataSource(paginatedData);
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: currentPage,
                total: total
            }
        });
    }

    const createNewRole = async (name: string, description: string | null, isDefaultRoleForStartedAccount: boolean) => {
        setTableLoading(true);

        const response = await RoleService.create({ name, description, isDefaultRoleForStartedAccount });

        if(response.success) {
            await fetchDataFromServer(true);
            messageApi.success(`Se creo satisfactoriamente el rol ${name} con ID: ${(response.data as { createdId: number }).createdId}`);
            handleCloseNewResourceModal();
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar crear el nuevo rol.', 3.5);
        }

        setTableLoading(false);
    }

    const editRole = async (id: number, name: string, description: string | null, isDefaultRoleForStartedAccount: boolean) => {
        setTableLoading(true);

        const response = await RoleService.update(id, { name, description, isDefaultRoleForStartedAccount });

        if(response.success) {
            await fetchDataFromServer(true);
            messageApi.success(`Se guardaron los cambios satisfactoriamente del rol ${name} con ID: ${id}`);
            handleCloseShowDetailResourceModal();
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar crear actualizar los datos del rol.', 3.5);
        }

        setTableLoading(false);
    }

    const associateRoleAndMenus = async (role: Role, keys: string[]) => {
        const response = await MenuService.associateMenusWithRole({
            roleId: role.id,
            menus: keys
        });

        if(response.success) {
            messageApi.success(<span>Se actualizó la visibilidad de los menus a los cuales tiene acceso el role: <b>{role.name}</b> de manera satisfactoria.</span>, 3.5);
            handleCloseMenuDetailModal();
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message || 'Hubo un error al intentar asociar los menus al rol, por favor inténtalo nuevamente.', 3.5);
        }
    }


    return (
        <>
            { contextHolder }

            <Layout breadcrumb={[
                { title: 'Ajustes' },
                { title: 'Roles' },
            ]}>
                <Card>
                    <div className="filter-container">
                        <Row gutter={[24, 16]}>
                            <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                                <label className="filter-search-container">Filtrar por: <Search placeholder="Nombre" onSearch={onSearch} disabled={tableLoading} allowClear/></label>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container">
                                <Button type="primary" onClick={createResourceModal}  disabled={tableLoading}><PlusOutlined /> Crear Rol</Button>
                            </Col>
                        </Row>
                    </div>
                </Card>

                <Card style={{ marginTop: '12px' }}>
                    <Table<Role> dataSource={filteredDataSource} bordered loading={tableLoading} size="small" scroll={{ x: 1300 }} pagination={tableParams.pagination} rowKey={(record) => record.id} onChange={handleTableChange}>
                        <Column title="ID" dataIndex="id" key="id" width={60} />

                        <Column title="Nombre" key="name" render={(row: Role) => (
                            <span>
                                {row.name}
                                {
                                    row.isDefaultRoleForStartedAccount && (
                                        <Tooltip title="Rol por defecto, usado al momento de la creación de un usuario.">
                                            <Button type="link" shape="circle" icon={<ExclamationCircleOutlined />}/>
                                        </Tooltip>
                                    )
                                }
                            </span>
                        )}/>

                        <Column
                            width={160}
                            align="center"
                            title="Fecha de creación"
                            dataIndex="createdAt"
                            key="createdAt"
                            render={(createdAt: Date) => (
                                <span>{dayjs(createdAt).format('DD/MM/YYYY h:mm A')}</span>
                            )}
                        />

                        <Column width={120} align="center" title="" key="actions" render={(row) => (
                            <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                                <Button size="small"><MoreOutlined /></Button>
                            </Dropdown>
                        )} />
                    </Table>
                </Card>

                {/* Modals */}
                <NewRoleModal
                    isOpen={isNewResourceModalOpen}
                    handleModalCancel={handleCloseNewResourceModal}
                    submit={createNewRole}
                />

                {
                    selectedRow && (
                        <DetailRoleModal
                            isOpen={isDetailModalOpen}
                            id={selectedRow.id}
                            handleModalCancel={handleCloseShowDetailResourceModal}
                            submit={editRole}
                        />
                    )
                }

                {
                    selectedRow && (
                        <MenuDetailModal
                            role={selectedRow}
                            isOpen={isMenuDetailModalOpen}
                            handleModalCancel={handleCloseMenuDetailModal}
                            menus={menus}
                            submit={associateRoleAndMenus}/>
                    )
                }
            </Layout>
        </>
    );
}

export default Roles;
