import React, {useEffect, useState} from 'react';
import {Alert, Button, Checkbox, Col, Divider, Form, Input, message, Modal, Row, Select} from "antd";
import {icons} from "../../../../components/DynamicIcon/DynamicIcon";

interface NewContainerModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    submit: (name: string, icon: string | null) => void;
}


function NewContainerModal({isOpen, handleModalCancel, submit}: NewContainerModalProps) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);

    const formattedIcons = Object.entries(icons).map(([key, value]) => ({
        value: key,
        label: key,
        icon: value
    })).map((item) => ({
        value: item.value,
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {item.icon && <span style={{ marginRight: 8 }}>{item.icon}</span>}
                {item.label}
            </div>
        )
    }));

    const defaultIcon = formattedIcons.find((record) => {
        return record.value === 'SwitcherOutlined'
    });

    useEffect(() => {
        if(isOpen) {
            form.resetFields();
            if(defaultIcon) {
                form.setFieldValue('icon', defaultIcon);
            }
        }
    }, [isOpen]);

    const onFinish = async (values: any) => {
        setLoading(true);

        await submit(
            values.title.trim(),
            values.icon === undefined ? null : (values.icon.key || null)
        );

        setLoading(false);
    }

    return (
        <>
            {contextHolder}

            <Modal
                title="Añadir contenedor"
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={480}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24} style={{ marginBottom: '18px' }}>
                        <Col xs={24}>
                            <Alert message="Al generarse el nuevo contenedor se añadirá directamente al final de la lista." type="info" showIcon />
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="title"
                                label="Título"
                                rules={[{ required: true, message: 'Debes de ingresar el título' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese el título"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="icon"
                                label="Icono"
                                rules={[{ required: true, message: 'Debes de seleccionar una opción para el icono' }]}
                            >
                                <Select
                                    placeholder="Seleccione una opción"
                                    allowClear
                                    labelInValue
                                    options={formattedIcons}
                                    showSearch
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default NewContainerModal;
