import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Input, message, Modal, Row, Select} from "antd";
import {ApiErrorData} from "../../../../models/ApiResponse";
import { Country } from '../../../../models/Country';
import { Company } from '../../../../models/Company';
import { CompanyService } from '../../../../services/CompanyService';

interface DetailCompanyModalProps {
    isOpen: boolean;
    id: number;
    handleModalCancel: () => void;
    submit: (id: number, name: string) => void;
    countries: Country[];
}

function DetailCompanyModal({isOpen, handleModalCancel, submit, id, countries}: DetailCompanyModalProps) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.resetFields();

        if(isOpen) {
            fetchData();
        }else {
            setLoading(false);
        }
    }, [isOpen]);

    const fetchData = async () => {
        setLoading(true);

        const response = await CompanyService.findById(id);

        if(response.success) {
            const data = response.data as Company;
            form.setFieldValue('name', data.name);
            form.setFieldValue('country', data.countryCode);

            setLoading(false);
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la compañía, por favor inténtalo nuevamente.', 3.5);
            handleModalCancel();
        }

        setLoading(false);
    }

    const onFinish = async (values: any) => {
        setLoading(true);

        await submit(
            id,
            values.name.trim()
        );

        setLoading(false);
    }

    return (
        <>
            {contextHolder}

            <Modal
                title={`Ver compañía [ID: ${id}]`}
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={480}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="name"
                                label="Nombre"
                                rules={[{ required: true, message: 'Debes de ingresar el nombre' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese el nombre"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="country"
                                label="País"
                                rules={[{ required: true, message: 'Debes de seleccionar una opción para el icono' }]}
                            >
                                <Select
                                    placeholder="Seleccione una opción"
                                    options={countries.map((record) => {
                                        return {
                                            value: record.code,
                                            label: record.name
                                        };
                                    })}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default DetailCompanyModal;
